<template>
  <BaseSpinner :loading="loading">
    <div class="card">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-12 col-md-6">
            <strong>Hits Consumed</strong>
          </div>
          <div class="col-12 col-md-6">
            <div class="progress">
              <div v-if="hits && hits.validations.used" class="progress-bar" role="progressbar" :style="`width: ${ percentageUsed }%`" :aria-valuenow="percentageUsed" aria-valuemin="0" aria-valuemax="100">{{ percentageUsed }}%</div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        You’ve used <strong>{{ hits && hits.validations.used }}</strong> of your {{ hits && hits.validations.capacity }} monthly included validations. <span v-if="resetFromNow">The scheduled reset is {{ resetFromNow }}.</span>
      </div>
      <div v-if="!$store.state.user.notifications.threshold_validations" class="card-footer">
        <router-link :to="{ name: 'notifications' }">
          <i class="fal fa-envelope-open mr-1"></i> Get notified for crossing thresholds
        </router-link>
      </div>
    </div>
  </BaseSpinner>
</template>

<script>
import moment from 'moment'
import BaseSpinner from '@/components/Base/BaseSpinner'

export default {
  components: {
    BaseSpinner,
  },

  data() {
    return {
      loading: true,
      hits: null,
    }
  },

  mounted() {
    this.retrieveHits()
  },

  methods: {
    async retrieveHits() {
      try {
        this.loading = true

        var { data } = await this.$http.get(`/hits`, {
          headers: { 'x-api-key': this.$store.getters.publicKey },
        })

        this.hits = data
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }
    },
  },

  computed: {
    resetFromNow() {
      return this.$store.state.user.hits.reset_date && moment(this.$store.state.user.hits.reset_date).endOf('day').fromNow()
    },
    percentageUsed() {
      if (!this.hits) {
        return 0
      }

      if (this.hits.deprecated) {
        return Math.floor((this.hits.used / this.hits.capacity) * 100)
      } else {
        return Math.floor((this.hits.validations.used / this.hits.validations.capacity) * 100)
      }
    },
  },
}
</script>
