<template>
  <div>
    <ValidationHits
      class="mb-3"
    />
    <div class="card mb-3">
      <div class="card-body">
        <form @submit.prevent="onSubmit">
          <div class="row">
            <div class="col-12 col-lg-4 mb-3 mb-lg-0">
              <a-input
                v-model="filters.query"
                allowClear
                placeholder="IE6388047V"
                @change="onQueryChange"
              />
            </div>
            <div class="col-8 col-lg-6">
              <a-range-picker
                class="w-100"
                :defaultValue="defaultPickerValues"
                :placeholder="['Requested since', 'Requested until']"
                @change="onDateChange"
              />
            </div>
            <div class="col-4 col-lg-2">
              <a-button htmlType="submit" block>
                Filter
              </a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div>
          <strong>Validations <span v-if="validationsCount !== null">({{ validationsCount }})</span></strong>
        </div>
        <ul class="list-inline mb-0">
          <li class="list-inline-item mr-3">
            <a href="https://vatstack.com/docs/validations" target="_blank">API Reference <i class="fal fa-external-link ml-1"></i></a>
          </li>
          <li class="list-inline-item">
            <ValidationCreate :visible="visible" @close="visible = false">
              <a-button type="primary" @click="visible = true">
                <i class="fal fa-plus mr-2"></i> New Validation
              </a-button>
            </ValidationCreate>
          </li>
        </ul>
      </div>
      <BaseSpinner :loading="loading">
        <table class="table mb-0">
          <thead class="thead-light">
            <tr>
              <th>Query</th>
              <th>Country</th>
              <th>
                <a-tooltip title="“Created” is the timestamp when you created the validation request. “Requested” is the date on which the data was obtained from a government service. Some data may be cached versions from up to 90 days ago due to a service downtime at the point of creation. Save your EU VAT ID in your account to enforce latest data with a unique consultation number (paid feature)." placement="top">
                  Created <i class="fad fa-fw fa-info-circle text-muted"></i>
                </a-tooltip>
              </th>
            </tr>
          </thead>
          <tbody>
            <ValidationItem
              v-for="validation in validations"
              :key="validation.id"
              :validation="validation"
              @success="listValidations()"
            />
            <tr v-if="!validationsCount">
              <td class="text-muted text-center" colspan="3">
                <i class="fad fa-empty-set mr-1"></i> No validations were found.
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="$store.state.livemode && !$store.getters.isSubscribed && !validationsCount" class="border-top position-relative">
          <div class="empty-overlay position-absolute p-5">
            <div class="row">
              <div class="col-12 col-lg-8 col-xl-6">
                <h2><i class="fad fa-badge-check mr-1"></i> Validate Business Customers</h2>
                <p>Vatstack’s powerful VAT number check for your business customers. Use them in combination with quotes and supplies to apply reverse charge.</p>
                <small>
                  <p class="mb-4">You need the <strong>Launch</strong> plan or above to perform validations.</p>
                </small>
                <a-button class="mr-2" type="primary" size="small" @click="$router.push({ name: 'subscription' })">
                  Upgrade
                </a-button>
                <a href="https://vatstack.com/validations">
                  <a-button size="small">
                    Learn More
                  </a-button>
                </a>
              </div>
            </div>
          </div>
          <table class="table mb-0">
            <tbody>
              <tr>
                <td><i class="fad fa-check-circle text-success mr-1"></i> IE6388047V</td>
                <td><img width="18" height="18" src="@/assets/images/countries/IE.svg" class="mr-1" style="margin-top: -4px;"> Ireland</td>
                <td>{{ convertDateTime(new Date(new Date() - 64 * 60000)) }}</td>
              </tr>
              <tr>
                <td><i class="fad fa-check-circle text-success mr-1"></i> LU20260743</td>
                <td><img width="18" height="18" src="@/assets/images/countries/LU.svg" class="mr-1" style="margin-top: -4px;"> Luxembourg</td>
                <td>{{ convertDateTime(new Date(new Date() - 128 * 60000)) }}</td>
              </tr>
              <tr>
                <td><i class="fad fa-times-circle text-danger mr-1"></i> DE811150486</td>
                <td><img width="18" height="18" src="@/assets/images/countries/DE.svg" class="mr-1" style="margin-top: -4px;"> Germany</td>
                <td>{{ convertDateTime(new Date(new Date() - 256 * 60000)) }}</td>
              </tr>
              <tr>
                <td><i class="fad fa-check-circle text-success mr-1"></i> IE9692928F</td>
                <td><img width="18" height="18" src="@/assets/images/countries/IE.svg" class="mr-1" style="margin-top: -4px;"> Ireland</td>
                <td>{{ convertDateTime(new Date(new Date() - 512 * 60000)) }}</td>
              </tr>
              <tr>
                <td><i class="fad fa-check-circle text-success mr-1"></i> DE813474672</td>
                <td><img width="18" height="18" src="@/assets/images/countries/DE.svg" class="mr-1" style="margin-top: -4px;"> Germany</td>
                <td>{{ convertDateTime(new Date(new Date() - 1024 * 60000)) }}</td>
              </tr>
              <tr>
                <td><i class="fad fa-check-circle text-success mr-1"></i> DE811128135</td>
                <td><img width="18" height="18" src="@/assets/images/countries/DE.svg" class="mr-1" style="margin-top: -4px;"> Germany</td>
                <td>{{ convertDateTime(new Date(new Date() - 2048 * 60000)) }}</td>
              </tr>
              <tr>
                <td><i class="fad fa-times-circle text-danger mr-1"></i> IT00811720580</td>
                <td><img width="18" height="18" src="@/assets/images/countries/IT.svg" class="mr-1" style="margin-top: -4px;"> Italy</td>
                <td>{{ convertDateTime(new Date(new Date() - 4096 * 60000)) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </BaseSpinner>
      <div class="card-footer">
        <BasePagination
          v-model="filters.page"
          :hasMore="hasMore"
          :pageCount="Math.ceil(validationsCount / filters.limit)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import BasePagination from '@/components/Base/BasePagination'
import BaseSpinner from '@/components/Base/BaseSpinner'
import ValidationCreate from '@/components/Validation/ValidationCreate'
import ValidationHits from '@/components/Validation/ValidationHits'
import ValidationItem from '@/components/Validation/ValidationItem'

export default {
  components: {
    BasePagination,
    BaseSpinner,
    ValidationCreate,
    ValidationHits,
    ValidationItem,
  },

  data() {
    return {
      loading: false,
      visible: false,
      hasMore: false,
      filters: {
        limit: 20,
        page: Number(this.$route.query.page) || 1,
        query: this.$route.query.query || null,
        requested_since: this.$route.query.requested_since || null,
        requested_until: this.$route.query.requested_until || null,
      },
      validations: [],
      validationsCount: null,
    }
  },

  async mounted() {
    await this.listValidations()
  },

  methods: {
    async listValidations() {
      try {
        this.loading = true

        var { data } = await this.$http.get(`/validations`, {
          headers: { 'x-api-key': this.$store.getters.secretKey },
          params: this.filters,
        })

        this.hasMore = data.has_more
        this.validations = data.validations
        this.validationsCount = data.validations_count
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }
    },
    onQueryChange(e) {
      this.filters.query = e.target.value
    },
    onDateChange(data) {
      this.filters.requested_since = data && data[0] ? data[0].format('YYYY-MM-DD') : null
      this.filters.requested_until = data && data[1] ? data[1].format('YYYY-MM-DD') : null
    },
    onSubmit() {
      this.$router.push({ name: 'validations', query: {
        query: this.filters.query,
        requested_since: this.filters.requested_since,
        requested_until: this.filters.requested_until,
      }})
    },
    convertDateTime(date) {
      return date && `${ date.toString().slice(0, 10) } ${ date.toString().slice(11, 16) }`
    },
  },

  computed: {
    defaultPickerValues() {
      if (this.filters.requested_since && this.filters.requested_until) {
        return [
          moment(this.filters.requested_since),
          moment(this.filters.requested_until),
        ]
      } else {
        return null
      }
    },
  },

  watch: {
    '$store.state.livemode'() {
      if (this.filters.page !== 1) {
        this.$router.replace({ name: 'validations' })
      } else {
        this.listValidations()
      }
    },
    'filters.page'(value) {
      this.$router.push({ name: 'validations', query: { ...this.$route.query, page: value }})
    },
  },
}
</script>

<style scoped>
.empty-overlay {
  animation: overlay 1s ease-in 1s forwards;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: rgb(255,255,255);
  background: linear-gradient(132deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.75) 100%);
}

@keyframes overlay {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
