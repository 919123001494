<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    validation: Object,
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    async onConfirm() {
      try {
        this.loading = true

        var { data } = await this.$http.delete(`/validations/${ this.validation.id }`, {
          headers: { 'x-api-key': this.$store.getters.secretKey },
        })

        this.$message.success('Validation has been deleted.')
        this.$emit('success', data)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        this.$emit('error')

        return false
      } finally {
        this.loading = false
      }
    },
  },

  watch: {
    visible(value) {
      if (value) {
        this.$confirm({
          title: 'Delete Validation',
          content: `This will delete the validation object for query ${ this.validation.query }. Continue with the deletion?`,
          okText: 'Delete',
          onOk: async () => {
            await this.onConfirm()
          },
          onCancel: () => {
            this.$emit('cancel')
          },
        })
      }
    },
  },
}
</script>
