<template>
  <div>
    <BaseSpinner v-if="loading" :loading="loading" />
    <div v-else class="card mb-3">
      <div class="card-header">
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'validations' }"><i class="fal fa-lg fa-chevron-left mr-2"></i> Validations</router-link>
          </li>
          <li class="breadcrumb-item active">
            {{ validation && validation.id }}
          </li>
        </ul>
      </div>
      <table class="table mb-0">
        <thead>
          <tr class="thead-light">
            <th class="w-25">Key</th>
            <th class="w-75">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!$store.state.livemode" class="table-warning">
            <td colspan="2">The below business registration information is dummy data for testing purposes only.</td>
          </tr>
          <tr v-if="validation.code">
            <td v-if="validation.code === 'SERVICE_UNAVAILABLE'" class="table-warning" colspan="2"><i class="fas fa-circle-notch fa-spin text-muted mr-1"></i> {{ $store.state.errorMessages[validation.code] }}</td>
            <td v-else class="table-danger" colspan="2"><strong>{{ validation.code }}</strong>: {{ $store.state.errorMessages[validation.code] }}</td>
          </tr>
          <tr>
            <td class="text-muted">Query</td>
            <td>{{ validation.query }}<span v-if="validation.type === 'eu_eori' || validation.type === 'gb_eori'"> (EORI)</span></td>
          </tr>
          <tr v-if="validation.external_id">
            <td class="text-muted">External ID</td>
            <td>{{ validation.external_id }}</td>
          </tr>
          <tr>
            <td class="text-muted align-top">Status</td>
            <td>
              <div class="row">
                <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                  <label class="label">Business Registration</label>
                  <div v-if="validation.active === true">
                    <i class="fad fa-check-circle text-success mr-1"></i> Valid
                  </div>
                  <div v-else-if="validation.active === false">
                    <i class="fad fa-times-circle text-danger mr-1"></i> Invalid
                  </div>
                  <div v-else>
                    <i class="fad fa-clock text-muted mr-1"></i> Unknown
                  </div>
                </div>
                <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                  <label class="label">VAT Cross-Border Registration</label>
                  <div v-if="validation.valid === true" >
                    <i class="fad fa-check-circle text-success mr-1"></i> Valid
                  </div>
                  <div v-else-if="validation.valid === false">
                    <i class="fad fa-times-circle text-danger mr-1"></i> Invalid
                  </div>
                  <div v-else>
                    <i class="fad fa-clock text-muted mr-1"></i> Unknown
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="text-muted align-top">Evidence</td>
            <td>
              <div class="row">
                <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                  <label class="label">Request Date</label>
                  <div>
                    <div v-if="validation.requested">{{ convertDate(validation.requested) }}</div>
                    <div v-else class="text-muted"><small>Not available</small></div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                  <label class="label">Consultation Number</label>
                  <div v-if="validation.consultation_number">
                    {{ validation.consultation_number }}
                  </div>
                  <div v-else>
                    <span class="text-muted"><small>Not available</small></span>
                    <span v-if="validation.type === 'eu_vat' && validation.valid === true">
                      <span v-if="$store.getters.isSubscribed" class="ml-2">
                        <small><router-link :to="{ name: 'regions' }">Add VAT ID</router-link></small>
                      </span>
                      <span v-else class="ml-2">
                        <small><router-link :to="{ name: 'billing' }">Upgrade</router-link></small>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="text-muted align-top">Company</td>
            <td>
              <label class="label">Name</label>
              <div v-if="validation.company_name">{{ validation.company_name }}</div>
              <div v-else class="text-muted"><small>Not available</small></div>
              <label class="label mt-4">Address</label>
              <div v-if="validation.company_address"><span style="white-space: pre;">{{ validation.company_address }}</span></div>
              <div v-else class="text-muted"><small>Not available</small></div>
            </td>
          </tr>
          <tr>
            <td class="text-muted">Country</td>
            <td>
              <div v-if="validation.country_code">
                <img width="18" height="18" :src="require(`@/assets/images/countries/${ validation.country_code }.svg`)" class="mr-1" style="margin-top: -4px;">
                {{ $store.state.countries[validation.country_code] }} ({{ validation.country_code }})
              </div>
              <div v-else class="text-muted"><small>Not available</small></div>
            </td>
          </tr>
          <tr>
            <td class="text-muted align-top">Created</td>
            <td>{{ convertDateTime(validation.created) }}</td>
          </tr>
        </tbody>
      </table>
      <div class="card-footer text-muted">
        <small>Last updated {{ convertDateTime(validation.updated) }}</small>
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-header">
        <strong>Raw JSON</strong>
      </div>
      <pre class="rounded-bottom p-4"><code>{{ validation }}</code></pre>
    </div>
  </div>
</template>

<script>
import BaseSpinner from '@/components/Base/BaseSpinner'

export default {
  components: {
    BaseSpinner,
  },

  data() {
    return {
      loading: true,
      validation: null,
    }
  },

  async mounted() {
    try {
      var { data } = await this.$http.get(`/validations/${ this.$route.params.id }`, {
        headers: { 'x-api-key': this.$store.getters.secretKey },
      })

      this.validation = data
    } catch (err) {
      if (err.response) {
        if (err.response.status === 404) {
          this.$router.push({ name: '404' })
        }

        this.$message.error(err.response.data.message)
      } else {
        this.$message.error(err.message)
      }

      return false
    } finally {
      this.loading = false
    }
  },

  methods: {
    convertDate(date) {
      return date && date.toString().slice(0, 10)
    },
    convertDateTime(date) {
      return date && `${ date.toString().slice(0, 10) } ${ date.toString().slice(11, 16) }`
    },
  },

  watch: {
    '$store.state.livemode'() {
      this.$router.replace({ name: 'validations' })
    },
  },
}
</script>
