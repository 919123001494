<template>
  <tr>
    <td>
      <router-link :to="{ name: 'validations-id', params: { id: validation.id }}" class="text-dark">
        <a-tooltip v-if="validation.valid === true" title="Valid" placement="left">
          <i class="fad fa-check-circle text-success mr-1"></i> {{ validation.query }}
        </a-tooltip>
        <a-tooltip v-else-if="validation.valid === false" title="Invalid" placement="left">
          <i class="fad fa-times-circle text-danger mr-1"></i> {{ validation.query }}
        </a-tooltip>
        <a-tooltip v-else :title="$store.state.errorMessages[validation.code]" placement="left">
          <i class="fas fa-circle-notch fa-spin text-muted mr-1"></i> {{ validation.query }}
        </a-tooltip>
      </router-link>
    </td>
    <td>
      <div v-if="validation.country_code">
        <img width="18" height="18" :src="require(`@/assets/images/countries/${ validation.country_code }.svg`)" class="mr-1" style="margin-top: -4px;">
        {{ $store.state.countries[validation.country_code] }}
        <span v-if="validation.type === 'eu_eori' || validation.type === 'gb_eori'"> (EORI)</span>
      </div>
    </td>
    <td>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <a-tooltip v-if="isCached" title="Cached" placement="right">
            {{ convertDateTime(validation.created) }} <i class="fad fa-database text-muted ml-2"></i>
          </a-tooltip>
          <span v-else>{{ convertDateTime(validation.created) }}</span>
        </div>
        <a-dropdown :trigger="['click']">
          <a-button size="small">
            <i class="fal fa-ellipsis-h"></i>
          </a-button>
          <a-menu slot="overlay" @click="onMenuClick">
            <a-menu-item key="delete">
              <ValidationItemDelete
                :validation="validation"
                :visible="visible"
                @cancel="visible = false"
                @error="visible = false"
                @success="onDelete"
              >
                <span class="text-danger"><i class="fal fa-fw fa-minus-circle"></i> Delete</span>
              </ValidationItemDelete>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </td>
  </tr>
</template>

<script>
import moment from 'moment'
import ValidationItemDelete from '@/components/Validation/ValidationItemDelete'

export default {
  components: {
    ValidationItemDelete,
  },

  props: {
    validation: Object,
  },

  data() {
    return {
      visible: false,
    }
  },

  computed: {
    isCached() {
      return moment(this.validation.created).format('YYYY-MM-DD') > moment(this.validation.requested).format('YYYY-MM-DD')
    },
  },

  methods: {
    onDelete() {
      this.visible = false
      this.$emit('success')
    },
    onMenuClick(e) {
      switch (e.key) {
        case 'delete':
          this.visible = true
        break
      }
    },
    convertDateTime(date) {
      return date && `${ date.toString().slice(0, 10) } ${ date.toString().slice(11, 16) }`
    },
  },
}
</script>
