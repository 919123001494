<template>
  <div>
    <slot />
    <a-modal
      :confirmLoading="loading"
      okText="Validate"
      title="New Validation Request"
      :visible="visible"
      @ok="onConfirm"
      @cancel="$emit('close')"
    >
      <a-form :form="form" @submit.prevent.native="onConfirm">
        <a-form-item label="Query" :colon="false" required>
          <a-input
            placeholder="IE6388047V"
            name="query"
            type="text"
            v-decorator="['query', {
              validate: [{
                trigger: 'change',
                rules: [{ required: true, message: 'Query is required.' }],
              }]
            }]"
          />
        </a-form-item>
        <a-form-item label="Number Type" :colon="false">
          <a-select
            name="type"
            v-decorator="['type']"
            :filterOption="filterOption"
            placeholder="Select a number type"
            showSearch
          >
            <a-select-option v-for="(label, type) in types" :key="type" :value="type">
              {{ label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <small>
        <p>A successful validation consumes <strong>1</strong> hit. If government services are temporarily unavailable, the request may take longer to complete. We can automatically notify your <router-link :to="{ name: 'endpoints' }">webhooks endpoint</router-link> upon completion.</p>
      </small>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
      types: {
        'au_gst': 'Australia ABN/ACN',
        'eu_vat': 'European Union VAT ID',
        'eu_oss_vat': 'European Union OSS VAT ID',
        'eu_eori': 'European Union EORI',
        'no_vat': 'Norway VAT ID',
        'gb_vat': 'United Kingdom VAT ID',
        'gb_eori': 'United Kingdom EORI',
        'ch_vat': 'Switzerland VAT ID',
      },
    }
  },

  created() {
    this.form = this.$form.createForm(this)
  },

  methods: {
    onConfirm() {
      this.form.validateFields(async (err, values) => {
        try {
          if (err) return false
          this.loading = true

          var { data } = await this.$http.post(`/validations`, values, {
            headers: { 'x-api-key': this.$store.getters.publicKey },
          })
        } catch (err) {
          if (err.response) {
            this.$message.error(err.response.data.message || this.$store.state.errorMessages[err.response.data.code])
          } else {
            this.$message.error(err.message)
          }

          return false
        } finally {
          this.loading = false
        }

        this.$router.push({ name: 'validations-id', params: { id: data.id }})
        this.$message.success('Validation request has been created.')
      })
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
  },
}
</script>
